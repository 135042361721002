import React from 'react'

const DocumentationIcon = () => {
  return (
    <svg width="80" height="95" viewBox="0 0 80 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H80V55C80 77.0914 62.0914 95 40 95C17.9086 95 0 77.0914 0 55V0Z" fill="url(#paint0_linear_1538_1387)"/>
    <path d="M23.9563 62.7431C25.3877 62.7431 26.4613 61.0918 26.4613 58.89L33.9762 55.9541C34.4533 56.8716 35.169 57.422 36.004 57.422C37.4354 57.422 38.509 55.5872 38.509 53.5688C38.509 53.2019 38.509 52.8349 38.3897 52.6514L46.501 36.5046C46.8589 36.6881 47.2167 36.8716 47.5746 36.8716C48.4096 36.8716 49.2446 36.1376 49.7217 35.2202L53.4195 38.8899C53.1809 39.4404 53.0616 40.1743 53.0616 40.9082C53.0616 43.1101 54.2545 44.7615 55.5666 44.7615C56.998 44.7615 58.0716 42.9266 58.0716 40.9082C58.0716 38.8899 56.8788 37.0551 55.5666 37.0551C55.0895 37.0551 54.6123 37.2385 54.2545 37.6055L50.0795 33.5688C50.0795 33.3853 50.0795 33.3854 50.0795 33.2019C50.0795 31.0001 48.8867 29.3486 47.5746 29.3486C46.1432 29.3486 45.0696 31.1836 45.0696 33.2019C45.0696 34.1193 45.3082 34.8533 45.5467 35.5872L37.674 51.1835C37.1969 50.4496 36.6004 49.8991 35.8847 49.8991C34.4533 49.8991 33.3797 51.7339 33.3797 53.7523C33.3797 53.9358 33.3797 54.1193 33.3797 54.4863L26.1034 57.2386C25.7455 55.9541 24.7913 55.0367 23.837 55.0367C22.4056 55.0367 21.332 56.8716 21.332 58.89C21.332 61.0918 22.5249 62.7431 23.9563 62.7431Z" fill="white"/>
    <path d="M10 11V20.5413V22.3762V71H70V22.3762V20.5413V11H10ZM68.6879 68.9817H11.0736V22.1927H68.6879V68.9817ZM68.6879 20.3579H11.0736V12.6514H68.6879V20.3579Z" fill="white"/>
    <path d="M13.9363 17.9725C14.4134 17.9725 14.7713 17.4222 14.7713 16.6882C14.7713 15.9543 14.4134 15.4038 13.9363 15.4038C13.4592 15.4038 13.1013 15.9543 13.1013 16.6882C13.1013 17.4222 13.4592 17.9725 13.9363 17.9725Z" fill="white"/>
    <path d="M17.3956 17.9725C17.8727 17.9725 18.2305 17.4222 18.2305 16.6882C18.2305 15.9543 17.8727 15.4038 17.3956 15.4038C16.9184 15.4038 16.5605 15.9543 16.5605 16.6882C16.5605 17.4222 16.9184 17.9725 17.3956 17.9725Z" fill="white"/>
    <path d="M20.855 17.9725C21.3321 17.9725 21.69 17.4222 21.69 16.6882C21.69 15.9543 21.3321 15.4038 20.855 15.4038C20.3779 15.4038 20.02 15.9543 20.02 16.6882C19.9007 17.4222 20.3779 17.9725 20.855 17.9725Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_1538_1387" x1="134.001" y1="-84.4992" x2="2.03892" y2="96.834" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1677F7"/>
    <stop offset="0.6" stop-color="#63A6FF"/>
    <stop offset="1" stop-color="#D9E9FF"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default DocumentationIcon