import React from 'react'

const FirstKey = () => {
    return (
        <svg width="65" height="65" viewBox="0 0 145 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-0.219971 4.98767V108.563H27.9177C33.5145 108.563 38.6873 106.119 41.4857 102.158L69.0086 63.18C70.3836 61.2327 71.1074 59.0238 71.1074 56.7753C71.1074 54.5268 70.3836 52.318 69.0086 50.3707L41.4857 11.3923C40.1101 9.44544 38.1322 7.82872 35.7507 6.70452C33.3691 5.58032 30.6677 4.98822 27.9177 4.98767H-0.219971Z" fill="#FCFFFE" />
        </svg>
    )
}

export default FirstKey