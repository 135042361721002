import React from 'react'

const Key = () => {
    return (
        <svg width="65" height="65" viewBox="0 0 136 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88.985 131.02H0V71.265H48.24C53.52 71.265 58.4 74.085 61.04 78.655L87.005 123.63C88.325 125.92 88.985 128.47 88.985 131.02Z" fill="#84B2FF" />
            <path d="M137 5.755V125.265H110.455C105.175 125.265 100.295 122.445 97.655 117.875L71.69 72.9C70.3928 70.6531 69.71 68.1044 69.71 65.51C69.71 62.9156 70.3928 60.3669 71.69 58.12L97.655 13.145C98.9527 10.8986 100.819 9.03314 103.065 7.73598C105.312 6.43883 107.861 5.75564 110.455 5.755H137Z" fill="#FCFFFE" />
            <path d="M85.925 0C85.925 2.55 85.265 5.1 83.945 7.39L57.98 52.365C56.6823 54.6114 54.8163 56.4769 52.5696 57.774C50.3228 59.0712 47.7743 59.7544 45.18 59.755H0V0H85.925Z" fill="#0C3A88" />
        </svg>
    )
}

export default Key