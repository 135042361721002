import React from 'react'

const Teams = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66 33V45C66 49.971 61.971 54 57 54C52.029 54 48 49.971 48 45V30H63C64.6575 30 66 31.3425 66 33ZM57 24C60.3135 24 63 21.3135 63 18C63 14.6865 60.3135 12 57 12C53.6865 12 51 14.6865 51 18C51 21.3135 53.6865 24 57 24Z" fill="#5059C9" />
            <path d="M52.5 33V49.5C52.5 58.1145 45.2385 65.034 36.501 64.467C28.5285 63.951 22.5 56.9745 22.5 48.9855V30H49.5C51.1575 30 52.5 31.3425 52.5 33ZM37.5 25.5C42.471 25.5 46.5 21.471 46.5 16.5C46.5 11.529 42.471 7.5 37.5 7.5C32.529 7.5 28.5 11.529 28.5 16.5C28.5 21.471 32.529 25.5 37.5 25.5Z" fill="#7B83EB" />
            <path d="M37.5 25.5C42.4706 25.5 46.5 21.4706 46.5 16.5C46.5 11.5294 42.4706 7.5 37.5 7.5C32.5294 7.5 28.5 11.5294 28.5 16.5C28.5 21.4706 32.5294 25.5 37.5 25.5Z" fill="#7B83EB" />
            <path opacity="0.05" d="M39 49.9785V30H22.5V48.9855C22.5 51.2865 23.0145 53.4975 23.916 55.5H33.477C36.528 55.5 39 53.028 39 49.9785Z" fill="black" />
            <path opacity="0.07" d="M22.5 30V48.9855C22.5 50.7255 22.8015 52.4115 23.331 54H33.318C35.904 54 37.9995 51.9045 37.9995 49.3185V30H22.5Z" fill="black" />
            <path opacity="0.09" d="M37.0005 30H22.5V48.9855C22.5 50.1885 22.6515 51.3615 22.911 52.5H33.159C35.28 52.5 36.999 50.781 36.999 48.66L37.0005 30Z" fill="black" />
            <path d="M33 51H9C7.3425 51 6 49.6575 6 48V24C6 22.3425 7.3425 21 9 21H33C34.6575 21 36 22.3425 36 24V48C36 49.6575 34.6575 51 33 51Z" fill="url(#paint0_linear_2107_4840)" />
            <path d="M27.1019 28.4985H14.8979V31.0785H19.4684V43.4985H22.5314V31.0785H27.1019V28.4985Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_2107_4840" x1="6.972" y1="21.972" x2="35.1045" y2="50.1045" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5961C3" />
                    <stop offset="1" stop-color="#3A41AC" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Teams