import React from 'react'

const SdkIcon = () => {
    return (
        <svg width="81" height="95" viewBox="0 0 81 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.384094 0H80.3841V55C80.3841 77.0914 62.4755 95 40.3841 95C18.2927 95 0.384094 77.0914 0.384094 55V0Z" fill="url(#paint0_linear_1538_1445)" />
            <path d="M36.0304 50.0825L29.7084 46.2293L36.0304 42.5596V40.1742L28.0384 45.1283V47.5136L36.0304 52.2843V50.0825Z" fill="white" />
            <path d="M44.1415 32.6512H42.5908L36.7459 58.7063H38.2966L44.1415 32.6512Z" fill="white" />
            <path d="M52.7301 45.1283L44.7381 40.1742V42.5596L51.0602 46.2293L44.7381 50.0825V52.2843L52.7301 47.5136V45.1283Z" fill="white" />
            <path d="M10.3841 11V20.5413V22.3762V71H70.3841V22.3762V20.5413V11H10.3841ZM69.1913 68.9816H11.5769V22.1926H69.1913V68.9816ZM11.5769 20.5413V12.8349H69.1913V20.5413H11.5769Z" fill="white" />
            <path d="M14.4398 15.2202C13.9626 15.2202 13.6048 15.7707 13.6048 16.5046C13.6048 17.2386 13.9626 17.7891 14.4398 17.7891C14.9169 17.7891 15.2748 17.2386 15.2748 16.5046C15.2748 15.9542 14.9169 15.2202 14.4398 15.2202Z" fill="white" />
            <path d="M17.8988 15.2202C17.4216 15.2202 17.0638 15.7707 17.0638 16.5046C17.0638 17.2386 17.4216 17.7891 17.8988 17.7891C18.3759 17.7891 18.7338 17.2386 18.7338 16.5046C18.7338 15.9542 18.3759 15.2202 17.8988 15.2202Z" fill="white" />
            <path d="M21.2391 15.2202C20.762 15.2202 20.4041 15.7707 20.4041 16.5046C20.4041 17.2386 20.762 17.7891 21.2391 17.7891C21.7162 17.7891 22.0741 17.2386 22.0741 16.5046C22.1934 15.9542 21.7162 15.2202 21.2391 15.2202Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1538_1445" x1="134.385" y1="-84.4992" x2="2.42302" y2="96.834" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1677F7" />
                    <stop offset="0.6" stop-color="#63A6FF" />
                    <stop offset="1" stop-color="#D9E9FF" />
                </linearGradient>
            </defs>
        </svg>


    )
}

export default SdkIcon