import React from 'react'

export const Stripe = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 115 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M114.038 24.6766C114.038 16.6226 110.144 10.2676 102.699 10.2676C95.2238 10.2676 90.7007 16.6226 90.7007 24.6136C90.7007 34.0833 96.0404 38.8653 103.705 38.8653C107.442 38.8653 110.269 38.0159 112.405 36.8204V30.5283C110.269 31.5979 107.819 32.2586 104.71 32.2586C101.663 32.2586 98.9616 31.1889 98.6161 27.4766H113.976C113.976 27.0676 114.038 25.4316 114.038 24.6766ZM98.5218 21.6878C98.5218 18.1327 100.689 16.6541 102.668 16.6541C104.584 16.6541 106.626 18.1327 106.626 21.6878H98.5218Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M78.5761 10.2676C75.4979 10.2676 73.519 11.7148 72.4197 12.7215L72.0113 10.7709H65.1011V47.4541L72.9536 45.7867L72.985 36.8833C74.1158 37.7013 75.7806 38.8653 78.5447 38.8653C84.1671 38.8653 89.287 34.335 89.287 24.362C89.2556 15.2384 84.0729 10.2676 78.5761 10.2676ZM76.6915 31.944C74.8383 31.944 73.7389 31.2833 72.985 30.4653L72.9536 18.7934C73.7703 17.8811 74.9011 17.2518 76.6915 17.2518C79.5498 17.2518 81.5286 20.4608 81.5286 24.5822C81.5286 28.7979 79.5812 31.944 76.6915 31.944Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.2959 8.41132L62.1799 6.71244V0.325928L54.2959 1.99334V8.41132Z" fill="#635BFF" />
            <path d="M62.1799 10.8022H54.2959V38.3303H62.1799V10.8022Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8467 13.1303L45.3442 10.8022H38.5596V38.3303H46.4121V19.6742C48.2653 17.2517 51.4064 17.6921 52.3801 18.0382V10.8022C51.375 10.4247 47.7 9.73258 45.8467 13.1303Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1413 3.97534L22.4772 5.6113L22.4458 30.8113C22.4458 35.4675 25.9323 38.8967 30.5811 38.8967C33.1567 38.8967 35.0413 38.4248 36.0779 37.8585V31.472C35.0727 31.881 30.1099 33.3282 30.1099 28.672V17.5034H36.0779V10.8023H30.1099L30.1413 3.97534Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.90822 18.7934C8.90822 17.5665 9.91335 17.0945 11.5781 17.0945C13.9653 17.0945 16.9807 17.8181 19.3678 19.108V11.7148C16.7608 10.6766 14.1851 10.2676 11.5781 10.2676C5.20181 10.2676 0.961426 13.6024 0.961426 19.1709C0.961426 27.8541 12.8973 26.4698 12.8973 30.2136C12.8973 31.6608 11.6409 32.1327 9.88194 32.1327C7.27489 32.1327 3.9454 31.0631 1.30694 29.6159V37.1035C4.22809 38.362 7.18066 38.8968 9.88194 38.8968C16.4153 38.8968 20.9069 35.6563 20.9069 30.0249C20.8755 20.6496 8.90822 22.317 8.90822 18.7934Z" fill="#635BFF" />
        </svg>
    )
}
