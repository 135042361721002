import React from 'react'

const Slag = () => {
    return (
        <svg width="45" height="50" viewBox="0 0 60 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2113_4959)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9982 0C18.6815 0.00254261 15.9972 2.79433 15.9997 6.23448C15.9972 9.67463 18.6839 12.4664 22.0007 12.469H28.0016V6.23702C28.0041 2.79687 25.3174 0.00508522 21.9982 0C22.0007 0 22.0007 0 21.9982 0ZM21.9982 16.6287H6.0005C2.68378 16.6312 -0.00293296 19.423 -0.000481582 22.8631C-0.00538434 26.3033 2.68133 29.0951 5.99805 29.1002H21.9982C25.3149 29.0976 28.0016 26.3058 27.9992 22.8657C28.0016 19.423 25.3149 16.6312 21.9982 16.6287Z" fill="#36C5F0" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.9997 22.8631C60.0021 19.423 57.3154 16.6312 53.9987 16.6287C50.682 16.6312 47.9953 19.423 47.9977 22.8631V29.1002H53.9987C57.3154 29.0976 60.0021 26.3058 59.9997 22.8631ZM43.9995 22.8631V6.23448C44.002 2.79687 41.3177 0.00508522 38.001 0C34.6843 0.00254261 31.9976 2.79433 32 6.23448V22.8631C31.9951 26.3033 34.6818 29.0951 37.9985 29.1002C41.3153 29.0976 44.002 26.3058 43.9995 22.8631Z" fill="#2EB67D" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.9985 62.2992C41.3153 62.2967 44.002 59.5049 43.9995 56.0648C44.002 52.6246 41.3153 49.8328 37.9985 49.8303H31.9976V56.0648C31.9951 59.5024 34.6818 62.2942 37.9985 62.2992ZM37.9985 45.668H53.9987C57.3154 45.6655 60.0021 42.8737 59.9997 39.4336C60.0046 35.9934 57.3179 33.2016 54.0012 33.1965H38.001C34.6843 33.1991 31.9976 35.9909 32 39.431C31.9976 42.8737 34.6818 45.6655 37.9985 45.668Z" fill="#ECB22E" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.67616e-06 39.4337C-0.0024497 42.8738 2.68426 45.6656 6.00098 45.6682C9.3177 45.6656 12.0044 42.8738 12.002 39.4337V33.1992H6.00098C2.68426 33.2018 -0.0024497 35.9935 1.67616e-06 39.4337ZM16.0002 39.4337V56.0624C15.9953 59.5025 18.682 62.2943 21.9987 62.2994C25.3154 62.2968 28.0021 59.5051 27.9997 56.0649V39.4388C28.0046 35.9986 25.3179 33.2068 22.0011 33.2018C18.682 33.2018 15.9977 35.9935 16.0002 39.4337Z" fill="#E01E5A" />
            </g>
            <defs>
                <clipPath id="clip0_2113_4959">
                    <rect width="60" height="62.3575" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Slag